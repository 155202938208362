import request from '@/utils/request'

// 查询工单列表
export function listWorkOrder(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order/list',
        method: 'get',
        params: query
    })
}

// 查询工单详细
export function getWorkOrder(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order/' + id,
        method: 'get'
    })
}

// 新增工单
export function addWorkOrder(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order',
        method: 'post',
        data: data
    })
}

// 修改工单
export function updateWorkOrder(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order',
        method: 'put',
        data: data
    })
}

// 删除工单
export function delWorkOrder(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order/' + id,
        method: 'delete',
        data: data
    })
}

// 提交工单
export function submitWorkOrder(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order/' + id,
        method: 'patch',
        data: data
    })
}

// 处理工单
export function handleWorkOrder(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order/handle',
        method: 'put',
        data: data
    })
}

export function getWorkOrderStatusStatistics() {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order/status/statistics',
        method: 'get',
    })
}

// 查询每个月工单数量
export function getWorkOderCountEveryMonth(params){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/work_order/work_order_count/statistics',
        method: 'get',
        params
    })
}

