<template>
  <div>
    <el-row >
      <el-col :span="6">
        <div v-show="showLeft">
          <div style="display: inline-block;font-size: 20px;font-weight: bold;color: #666666;text-align: center" >
            <p>安装计划总数</p>
            <p style="color:#8E84F3">{{ total }}个</p>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <div ref="pie" style="height: 23vh; width: 500px;top:-30px"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getInstallationPlanStatusStatistics } from "@/api/installation_plan";
import { isResOK } from "@/api/response";

export default {
  name: "InstallPlanStatusStatistics",
  data() {
    return {
      data: [],
      total: 0,
      showLeft:false
    };
  },
  computed: {
    // 图表默认配置
    chartOption() {
      return {
        title: {
          left: "center",
          padding: [20, 0, 0, 0],
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `${params.marker} ${params.name}`;
          },
        },
        legend: {
          right: "10%",
          top: "center",
          orient: "horizontal",
          icon: "circle",
          width:300,
          formatter: ["{a|{name}}"].join("\n"),
          textStyle: {
            rich: {
              a: {
                width: 80,
                fontSize: 12,
                lineHeight: 12,
              },
            },
          },
        },
        series: [
          {
            name: "状态分布",
            type: "pie",
            radius: ["40%", "50%"],
            center: ["30%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getInstallationPlanStatusStatistics().then((res) => {
        if (isResOK(res)) {
          this.data = res.data;
          this.drawChart();

          this.total = this.data.reduce(
            (accumulator, currentValue) => accumulator + currentValue.count,
            0
          );
        }
      });
    },
    drawChart() {
      this.chartOption.series[0].data = [];
      this.data.forEach((item) => {
        this.chartOption.series[0].data.push({
          name: item.statusName+`:  ${item.count}`,
          value: item.count,
        });
      });

      this.chart ||= this.$echarts.init(this.$refs.pie);
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true);
      this.chart.on("rendered", () => {
            this.showLeftDiv(); // 图表渲染完成后显示左侧的div
          });
      // 添加表格自适应
      window.addEventListener("resize", () => {
        this.chart.resize();
      });
    },
    showLeftDiv() {
      this.showLeft = true;
    }
  },
};
</script>

<style scoped></style>
