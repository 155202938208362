import request from '@/utils/request'

// 查询安装任务列表
export function listInstallationTask(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task/list',
        method: 'get',
        params: query
    })
}

// 查询安装任务详细
export function getInstallationTask(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task/' + id,
        method: 'get'
    })
}

// 新增安装任务
export function addInstallationTask(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task',
        method: 'post',
        data: data
    })
}

// 修改安装任务
export function updateInstallationTask(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task',
        method: 'put',
        data: data
    })
}

// 删除安装任务
export function delInstallationTask(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task/' + id,
        method: 'delete'
    })
}
// 任务状态统计
export function getStatusStatistics(query){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task/status/statistics',
        method: 'get',
        params: query
    })
}

// 安装设备
export function installDevice(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task/install',
        method: 'put',
        data: data
    })
}

// 查询每个月安装任务数量
export function getInstallationTaskCountEveryMonth(params){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task/installation_task_count/statistics',
        method: 'get',
        params
    })
}

// 查询安装团队未完成任务数量
export function getTeamIncompleteTaskStatistic(params){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_task/team_incomplete_task/statistics',
        method: 'get',
        params
    })
}

