<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-card>
          <el-row>
            <el-col>
              <project-status-statistics/>
            </el-col>
            <el-col>
              <div v-if="isShowProjectCount" style="height: 17vh">
                <el-empty
                    :image-size="67"
                    style=" margin-bottom: 20px"
                    description="暂无数据"
                />
              </div>
              <project-count-trend @showProjectCount="showProject" v-else/>
            </el-col>
            <el-col>
              <div
                  style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #666666;
                  position: relative;
                "
              >
                <span>项目数量趋势</span>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <el-row>
            <el-col>
              <install-plan-status-statistics/>
            </el-col>
            <el-col>
              <div v-if="isShowInstallPlanCount" style="height: 17vh">
                <el-empty
                    :image-size="67"
                    style="margin-bottom: 20px"
                    description="暂无数据"
                />
              </div>
              <install-plan-count-trend
                  @showInstallPlan="showInstallPlan"
                  v-else
              />
            </el-col>
            <el-col :span="24">
              <div
                  style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #666666;
                  position: relative;
                "
              >
                <span>安装计划数量趋势</span>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-card>
          <el-row>
            <el-col>
              <work-order-status-statistics/>
            </el-col>
            <el-col>
              <div v-if="isShowWorkOrderCount" style="height: 17vh">
                <el-empty
                    :image-size="67"
                    style=" margin-bottom: 20px"
                    description="暂无数据"
                />
              </div>
              <work-order-count-trend @showWorkOrder="showWorkOrder" v-else/>
            </el-col>
            <el-col>
              <div
                  style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #666666;
                  position: relative;
                "
              >
                <span>工单数量趋势</span>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <el-row>
            <el-col>
              <install-task-status-statistics/>
            </el-col>
            <el-col :span="24">
              <div v-if="isShowInstallTaskCount" style="height: 17vh">
                <el-empty
                    style=" margin-bottom: 20px"
                    description="暂无数据"
                    :image-size="67"
                />
              </div>
              <install-task-count-trend
                  @showInstallTask="showInstallTask"
                  v-else
              />

            </el-col>
            <el-col>
              <div
                  style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #666666;
                  position: relative;
                "
              >
                <span>安装任务数量趋势</span>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ProjectStatusStatistics from "@/view/index/ProjectStatusStatistics.vue";
import ProjectCountTrend from "@/view/index/ProjectCountTrend.vue";
import InstallPlanStatusStatistics from "@/view/index/InstallPlanStatusStatistics.vue";
import InstallPlanCountTrend from "@/view/index/InstallPlanCountTrend.vue";
import InstallTaskStatusStatistics from "@/view/index/InstallTaskStatusStatistics.vue";
import InstallTaskCountTrend from "@/view/index/InstallTaskCountTrend.vue";
import WorkOrderStatusStatistics from "@/view/index/WorkOrderStatusStatistics.vue";
import WorkOrderCountTrend from "@/view/index/WorkOrderCountTrend.vue";

export default {
  name: "index",
  components: {
    ProjectStatusStatistics,
    ProjectCountTrend,
    InstallPlanStatusStatistics,
    InstallPlanCountTrend,
    InstallTaskStatusStatistics,
    InstallTaskCountTrend,
    WorkOrderStatusStatistics,
    WorkOrderCountTrend,
  },
  data() {
    return {
      isShowProjectCount: false,
      isShowInstallPlanCount: false,
      isShowInstallTaskCount: false,
      isShowWorkOrderCount: false,
    };
  },
  methods: {
    showProject(isShow) {
      this.isShowProjectCount = isShow;
    },
    showInstallPlan(isShow) {
      this.isShowInstallPlanCount = isShow;
    },
    showInstallTask(isShow) {
      this.isShowInstallTaskCount = isShow;
    },
    showWorkOrder(isShow) {
      this.isShowWorkOrderCount = isShow;
    },
  },
};
</script>

<style scoped>
.el-card {
  margin-top: 5px;
  margin-left: 5px;
  width: 49vw;
  height: 48.7vh;
}

.el-empty {
  padding: 0;
}
</style>
