import request from '@/utils/request'

// 查询安装计划列表
export function listInstallationPlan(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/list',
        method: 'get',
        params: query
    })
}

// 查询安装计划详细
export function getInstallationPlan(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/' + id,
        method: 'get'
    })
}

// 新增安装计划
export function addInstallationPlan(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan',
        method: 'post',
        data: data
    })
}

// 修改安装计划
export function updateInstallationPlan(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan',
        method: 'put',
        data: data
    })
}

// 删除安装计划
export function delInstallationPlan(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/' + id,
        method: 'delete',
        data: data
    })
}

// 提交安装计划
export function submitInstallationPlan(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/' + id+'/submit',
        method: 'post',
        data: data
    })
}


export function getInstallationPlanStatusOptions() {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/status/options',
        method: 'get'
    })
}

// 查询安装计划状态统计数据
export function getInstallationPlanStatusStatistics(){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/status/statistics',
        method: 'get'
    })
}

// 查询安装计划（不含草稿、退回）
export function listInstallationPlanList(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/plan_list',
        method: 'get',
        params: query
    })
}

// 安装计划 接单
export function receiveInstallationPlan(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/' + id+'/receive',
        method: 'post'
    })
}

// 安装计划 退单
export function refuseInstallationPlan(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/' + id+'/refuse',
        method: 'post'
    })
}

// 查询每个月安装计划数量
export function getInstallationPlanCountEveryMonth(params){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/installation_plan_count/statistics',
        method: 'get',
        params
    })
}

// 校验计划名称
export function checkName(data){
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/check_name',
        method: 'post',
        data: data
    })
}

// 查询安装队伍每日未完成安装数量
export function selectDayTeamIncompleteTaskStatistic(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/installation_plan/day_team_incomplete_task_statistic',
        method: 'get',
        params: query
    })
}
