<template>
    <div
      ref="trend"
      style="height:17vh; width: 100%; top:-30px"
    ></div>
</template>

<script>
import { getInstallationPlanCountEveryMonth } from "@/api/installation_plan";
import { isResOK } from "@/api/response";
import dayjs from "dayjs";

export default {
  name: "InstallPlanCountTrend",
  emits: ["showInstallPlan"],
  data() {
    return {
      data: [],
      showdata: true,
    };
  },
  computed: {
    // 图表默认配置
    chartOption() {
      return {
        tooltip: {
          trigger: "axis",
          position: (point, params, dom, rect, size) => {
            // size为当前窗口大小
            if (size.viewSize[0] / 2 >= point[0]) {
              // 其中point为当前鼠标的位置
              return [point[0] + 20, "10%"];
            } else {
              // 其中point为当前鼠标的位置
              return [point[0] - 200, "10%"];
            }
          },

          formatter: (params) => {
            let relVal = params[0].name;
            for (let i = 0; i < params.length; i++) {
              relVal += `<br/>${params[i].marker}
                <span style="width: 100px; overflow: hidden;text-overflow: ellipsis; display: inline-block; vertical-align: bottom">
                安装计划数量
                </span>
                &nbsp;&nbsp;
                ${params[i].value}`;
            }
            return relVal;
          },
        },
        grid: {
          y: 30, //上下距离
          y2: 30,
          x2: 30,
          x: 20,
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          // 坐标轴刻度
          axisTick: {
            show: false
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [],
      };
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getInstallationPlanCountEveryMonth({
        beginDate: parseInt(dayjs().subtract(11, "month").format("YYYYMM")),
        endDate: parseInt(dayjs().format("YYYYMM")),
      }).then((res) => {
        if (isResOK(res)) {
          this.data = res.data;
          this.drawChart();
        }
        this.showdata = this.data.every((item) => {
          return item.count == 0;
        });
        this.$emit('showInstallPlan',this.showdata)
      });
    },
    setSeries() {
      // 从全局色中获取一个颜色
      this.chartOption.series.push({
        type: "line",
        data: this.$lodash.map(this.data, "count"),
      });
    },
    drawChart() {
      this.chartOption.xAxis.data = [];
      this.chartOption.series = [];
      if (this.data.length > 0) {
        this.chartOption.xAxis.data = this.$lodash.map(this.data, "date");
        this.setSeries();
      }
      this.chart ||= this.$echarts.init(this.$refs.trend);
      // 全部重新加载-解决tooltip.formatter params不更新的问题
      this.chart.setOption(this.chartOption, true);
      // 添加表格自适应
      window.addEventListener("resize", () => this.chart.resize());
    },
  },
};
</script>

<style scoped>
.el-empty__description {
  margin-top: 0;
}
</style>
